import css from "./ListingCard.module.css";

const ListingCardOffers = () => {
  return (
    <div className={css.metaItem}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_280_1035"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect
            width="18"
            height="18"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_280_1035)">
          <path
            d="M11.475 12.5252L12.525 11.4752L9.75 8.70018V5.25018H8.25V9.30018L11.475 12.5252ZM9 16.5002C7.9625 16.5002 6.9875 16.3033 6.075 15.9096C5.1625 15.5158 4.36875 14.9814 3.69375 14.3064C3.01875 13.6314 2.48438 12.8377 2.09063 11.9252C1.69687 11.0127 1.5 10.0377 1.5 9.00018C1.5 7.96268 1.69687 6.98768 2.09063 6.07518C2.48438 5.16268 3.01875 4.36893 3.69375 3.69393C4.36875 3.01893 5.1625 2.48456 6.075 2.09081C6.9875 1.69706 7.9625 1.50018 9 1.50018C10.0375 1.50018 11.0125 1.69706 11.925 2.09081C12.8375 2.48456 13.6313 3.01893 14.3063 3.69393C14.9813 4.36893 15.5156 5.16268 15.9094 6.07518C16.3031 6.98768 16.5 7.96268 16.5 9.00018C16.5 10.0377 16.3031 11.0127 15.9094 11.9252C15.5156 12.8377 14.9813 13.6314 14.3063 14.3064C13.6313 14.9814 12.8375 15.5158 11.925 15.9096C11.0125 16.3033 10.0375 16.5002 9 16.5002ZM9 15.0002C10.6625 15.0002 12.0781 14.4158 13.2469 13.2471C14.4156 12.0783 15 10.6627 15 9.00018C15 7.33768 14.4156 5.92206 13.2469 4.75331C12.0781 3.58456 10.6625 3.00018 9 3.00018C7.3375 3.00018 5.92188 3.58456 4.75313 4.75331C3.58438 5.92206 3 7.33768 3 9.00018C3 10.6627 3.58438 12.0783 4.75313 13.2471C5.92188 14.4158 7.3375 15.0002 9 15.0002Z"
            fill="#4B4B4F"
          />
        </g>
      </svg>
      <div>0 offerte</div>
    </div>
  );
}

export default ListingCardOffers;
